// responsive variables
$respRightForFull: 240px;
$respLeftForFull: 256px;
$respMarginLeftForFull: 278px;

// > 992 iPad Horizontal (1040)
$respRightUnder1040: 240px;
$respMarginRightUnder1040: 266px;
$respLeftUnder1040: 256px;
$respMarginLeftUnder1040: 278px;

// < 992 iPad Vertical (991)
$respRightUnder991: 240px;
$respMarginRightUnder991: 266px;
$respLeftDisplayUnder991: none;
$respLeftUnder991: 0px;
$respMarginLeftUnder991: 0px;

// < 768 phones (767)
$respRightUnder767: 240px;
$respMarginRightUnder767: 266px;
$respLeftUnder767: 0px;
$respMarginLeftUnder767: 0px;
