@import 'variables';

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
div,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  /*	display: block;
	    -moz-box-sizing: border-box; 
	box-sizing: border-box; 	 
	    -webkit-box-sizing: border-box;*/
}

body {
  line-height: 1.4;
  font-size: 15px;
  /*width: 1200px;*/
}

ol,
ul {
  list-style: none;
}

em {
  font-family: 'OpenSansItalic';
  font-style: normal;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*ml*/
.clear {
  clear: both;
}

body {
  /*font-family: tahoma, verdana, arial, sans-serif;*/
  font-family: 'OpenSansRegular', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-family: 'OpenSansSemiBold';
  font-weight: normal;
}

.content {
  width: 1100px;
  min-width: 1100px;
  margin: auto;
  position: relative;
}

#left-aside {
  float: left;
  /*width: 280px;*/
  width: $respLeftForFull;
}

#right-aside {
  float: right;
  width: $respRightForFull;
}

#main {
  margin-left: $respMarginLeftForFull;
  width: 559px;
  margin-bottom: 25px;
}

#top-line {
  background: #0860a8;
  width: 100%;
  height: 10px;
}

#header {
  background: #5ab0f5;
  color: white;
}

#left-topper {
  float: left;
  width: 382px;
  padding-top: 20px;

  div {
    text-align: right;
    position: relative;
    width: 100%;
  }
}

#right-topper {
  float: right;
  text-align: right;
}

a {
  color: #0071bc;
  text-decoration: underline;
}

#topper {
  height: 128px;
}

#mail {
  display: inline-block;
  margin-top: 7px;
  /*font-size: 17px;*/
  padding-left: 38px;
  margin-right: 14px;
  background-image: url('../img/mail_light.png');
  background-repeat: no-repeat;
  background-position: 0px 5px;

  background-size: 23px;

  a {
    /*text-decoration: none;*/
    color: #e5f3ff;
    font-size: 17px;
    border-bottom: dashed #e5f3ff 1px;
    text-decoration: none;
  }
}

#phone {
  margin-top: 7px;
  font-size: 17px;
  display: inline-block;
  padding-left: 31px;
  background-image: url('../img/phone_light.png');
  background-repeat: no-repeat;
  background-position: 0px 4px;

  background-size: 23px;
  color: #e5f3ff;
}

#footer {
  background: #2d4151;
  /*height: 168px;*/
  position: relative;
}

#slider-big-outer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #d7edff;
}

#left-logo-foo {
  position: relative;
  top: -6px;
}

#top-menu {
  width: 564px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1000;

  a,
  .nav-header {
    display: block;
    float: left;
    height: 51px;
    width: 141px;
    line-height: 51px;
    color: white;
    text-decoration: none;
    text-align: center;
    font-size: 19px;
  }

  .menu_01,
  .item-101 {
    background: #ef4929;
  }

  .menu_02,
  .item-102 {
    background: #d4c927;
  }

  .menu_03,
  .item-103,
  .item-157 .nav-header {
    background: #3087c8;
  }

  .menu_04,
  .item-104 {
    background: #ee3696;
  }

  .menu_01:hover {
    background: #dc3211;
  }

  .menu_02:hover {
    background: #b9af23;
  }

  .menu_03:hover {
    background: #1e6fab;
  }

  .menu_04:hover {
    background: #d5217f;
  }
}

#middle {
  padding-top: 63px;
  padding-bottom: 10px;
  overflow: auto;
}

.vkvidget {
  margin-top: 25px;
  /*border: 1px solid grey;*/
}

#slider-big {
  position: relative;
  width: 100%;
  height: 100%;
}

#slider-big-outer {
  .Joombig-beautiful-banner+a.prev {
    background: url('../img/slider-left-arrow.png') no-repeat scroll 0 0 transparent;
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: -100px;
    margin-top: -17px;
    /*background: red;*/
  }

  .Joombig-beautiful-banner+a.prev+a.next {
    background: url('../img/slider-right-arrow.png') no-repeat scroll 0 0 transparent;
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: -100px;
    margin-top: -17px;
    /*background: red;*/
  }

  .skdslider {
    .slide-desc {
      background: #2682cd;
      left: 0px;
      top: 0px;
      padding: 0px;
      position: absolute;
      /* bottom: 15%; */
      width: 450px;
      height: 350px;
    }

    .slide-desc>h2 {
      color: white;
      font-size: 25px;
      margin-bottom: 0px;
      margin-top: 0px;
      text-transform: none;
      height: 58px;
      line-height: 58px;
      background: #0860a8;
    }

    .slide-desc>p {
      color: white;
      font-size: 16px;
      margin-top: 20px;
      margin-left: 35px;
    }
  }
}

#slider-big-outer a.more {
  color: #990000;
  font-size: 20px;
  text-decoration: none;
  background: url('../img/opa.png') no-repeat scroll 0 0 transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 222;
  top: 0px;
  left: 0px;
  cursor: pointer;
}

.right-img {
  margin-bottom: 22px;
}

.aside-block {
  background: #f0f5f9;
  padding: 0px 12px 20px 14px;
  margin-bottom: 30px;

  h1 {
    font-size: 19px;
    background: #ef4929;
    color: white;
    display: inline-block;
    line-height: 35px;
    min-width: 88px;
    padding: 0px 7px 3px 7px;
  }
}

#left-aside-quotes {
  padding-bottom: 0px;
}

#srq_quote {
  color: #4f555b;
  padding: 22px 0px 15px 0px;
}

#srq_author {
  background: white;
  color: black;
  font-size: 13px;
  width: 230px;
  position: relative;
  left: -16px;
  padding-top: 14px !important;
  padding-right: 15px;
  text-align: left;
  padding-left: 15px;

  b {
    font-weight: normal;
  }
}

#left-aside-knowwhat {
  padding: 0px 15px 0px 15px;

  #srq_author {
    display: none;
  }

  .newsflash {

    span,
    p {
      color: #4f555b !important;
    }
  }
}

.aside-block .inner {
  padding: 22px 0 15px;
}

#left-aside-quotes .inner {
  padding: 22px 0 0 0;
}

.item-page {
  .page-header h1 {
    display: none;
  }

  .icons {
    display: none;
  }

  .article-info {
    display: none;
  }

  .pager {
    display: none;
  }

  h2.page_h2 {
    padding-left: 40px;
  }

  h3.page_h3 {
    padding-left: 85px;
    font-size: 24px;
    background-color: #f4f2d3;
    background-image: url('../img/page_h3.png');
    background-repeat: no-repeat;
    background-position: 40px 50%;
    background-size: 25px;
    line-height: 48px;
    margin-top: 28px;
  }
}

.item-page h1.page_h1,
.module-block-name {
  padding-left: 37px;
  font-size: 24px;
  background-image: url('../img/page_h1.png');
  background-repeat: no-repeat;
  background-position: 0px 2px;
  background-size: 28px;
  line-height: 30px;
  margin-bottom: 8px;
}

.item-page h1.page_h1.prepod_h1,
.module-block-name {
  margin-top: 24px;
}

#google-map {
  margin-bottom: 25px;
}

#google-map h4 {
  padding-left: 41px;
  font-size: 29px;
  color: #2682cd;
  background-image: url('../img/page_h4.png');
  background-repeat: no-repeat;
  background-position: 0px 50%;
  background-size: 25px;
  line-height: 92px;
}

#google-map-show {
  padding-left: 38px;
  height: 300px;
}

#google-map-address {
  padding-left: 38px;
}

.page-block #google-map-show {
  /*padding-left: 0px;*/
}

.page-block #google-map-address {
  /*padding-left: 0px;*/
  margin-bottom: 40px;
}

#google-map-address td {
  vertical-align: top;
}

#homepage-how-find-left {
  width: 265px;
  margin-right: 20px;
}

#homepage-how-find-right {
  width: 240px;

  .mail {
    margin-bottom: 5px;
    background-image: url('../img/mail_dark.png');
    background-repeat: no-repeat;
    background-position: 0px 3px;
    background-size: 23px;
    padding-left: 35px;

    a {
      color: #0b63a9;
      border-bottom: dashed #0b63a9 1px;
      text-decoration: none;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .phone {
    padding-left: 35px;
    background-image: url('../img/phone_dark.png');
    background-repeat: no-repeat;
    background-position: 0px 0px;
    background-size: 23px;
    color: #4a5b68;
    font-size: 18px;
  }
}

#homepage-faq {
  h5 {
    padding-left: 35px;
    font-size: 29px;
    color: #d4c927;
    background-image: url('../img/page_h5.png');
    background-repeat: no-repeat;
    background-position: 0px 50%;
    background-size: 27px;
    line-height: 70px;
    margin-top: 5px;
  }

  h6 {
    padding-left: 37px;
    font-size: 24px;
    margin-bottom: 11px;

    strong {
      font-weight: normal;
    }
  }

  p {
    padding-left: 39px;
    margin-bottom: 15px;
  }
}

#footer {
  color: #94a1ab;
  padding: 35px 0px 20px 0px;
}

#foo-menu {
  float: left;
  height: 200px;
  overflow: auto;
  width: 800px;
  margin-left: 7px;

  ul {
    margin: 0px;
    padding: 0px;
  }
}

#foo-soc {
  float: right;
  margin-right: 12px;
}

#footer p {
  font-size: 11px;
  margin-top: 60px;
}

#foo-menu {
  li {
    display: inline-block;
    position: relative;
    margin-right: 20px;
    list-style: none;

    a {
      color: #c3ced7;
      text-decoration: underline;
      cursor: pointer;
    }

    a:hover {
      color: #ffc67b;
    }

    ul {
      position: absolute;
      top: 30px;
      left: 15px;

      li {
        display: list-item;

        a {
          color: #86949f;
          font-size: 12px;
        }

        a:hover {
          color: #ffc67b;
        }
      }
    }
  }

  li.item-101 {
    width: 138px;
  }

  li.item-102 {
    width: 218px;
  }

  li.item-157 {
    width: 227px;
  }

  li.item-163 {}
}

#left-aside-sertificates .random-image {
  margin-top: 15px;
}

#homepage-gallery {
  /*    margin-bottom: 45px;*/
  margin-bottom: 15px;
  position: relative;

  h4 {
    padding-left: 38px;
    font-size: 29px;
    color: #2682cd;
    background-image: url('../img/page_h4_gallery.png');
    background-repeat: no-repeat;
    background-position: 0px 50%;
    background-size: 30px;
    line-height: 50px;
    margin-bottom: 16px;
  }
}

#fb_wall_ml {
  margin-bottom: 45px;
}

#homepage-gallery #gallery-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1000000;
  background: url('../img/opa.png') repeat;
}

#phocagallery-module-ri {
  padding-left: 38px;
}

#fb_wall_ml h4 {
  padding-left: 38px;
  font-size: 29px;
  color: #2682cd;
  background-image: url('../img/fb_homepage.png');
  background-repeat: no-repeat;
  background-position: 0px 50%;
  background-size: 26px;
  line-height: 50px;
  margin-bottom: 16px;
}

#fb_wall_ml .fb-like-box {
  padding-left: 38px;
}

.color-blue {
  color: #0b63a9;
}

#homepage-how-find-left .color-blue {
  text-decoration: underline;
}

.category-list {
  .content-category {
    h2 {
      color: black;
      font-size: 26px;
      font-weight: normal;
      height: 32px;
      line-height: 32px;
      margin: 0 0 10px;
      margin-bottom: 35px;
      padding: 0 10px;
      text-align: left;
    }
  }

  .category-desc {
    padding-left: 10px;

    p {
      font-size: 15px;
      margin: 13px 0px;
      line-height: 1.4;

      strong {
        font-family: 'OpenSansSemiBold';
      }
    }
  }
}

.content-category {

  a:link,
  a:visited {
    color: #0071bc;
    text-decoration: underline;
  }

  table.category {
    line-height: 1.4;
    margin: 1em;
    padding-left: 1em;
    margin-left: 60px;

    td {
      display: list-item;
      line-height: 1.4;
      font-size: 15px;
      list-style-image: url(/templates/esperanto/img/li_15_15.png);

      a {
        position: relative;
        top: -3px;
      }
    }
  }
}

.page-header h2 {
  background: url('/templates/esperanto/images/title_bg.gif') repeat-x scroll center top #7b8287;
  color: #ffffff;
  font-size: 13pt;
  height: 30px;
  line-height: 30px;
  margin: 0 0 10px;
  padding: 0 10px;
  text-align: left;
}

.homepage .page-header h2 {
  display: none;
}

.innerpage .page-header h2,
.blog .subheading-category {
  background: none;
  color: black;
  font-size: 26px;
  height: auto;
  line-height: 1.4;
  margin: 0 0 10px;
  padding: 0 10px;
  text-align: left;
  position: relative;
  top: -5px;
  margin-bottom: 30px;
  padding-left: 0px;
}

.innerpage {
  .item-page {

    h1,
    h2,
    h3,
    h4,
    h5 {
      padding-left: 0px;
    }

    h3.page_h3 {
      padding-left: 85px;
    }
  }

  .blog .page-header h2 {
    color: #0068b5;
    font-size: 20px;
    padding: 0 0 5px 10px;
    margin: 1.7em 0 0 0;
    line-height: 1.2;
  }

  .page-header+div {
    /*padding-left: 15px;*/
    padding-left: 0px;

    p {
      padding-left: 0px;
    }
  }
}

.subtitle {
  color: #0071bc;
  font-size: 11pt;
  margin: 14px 0px;
  padding-left: 10px;
}

.item-page p {
  padding-left: 10px;
  margin-bottom: 15px;
}

ul {
  padding-left: 1em;
  margin: 1em;
  line-height: 1.4;
  list-style: inherit;
}

.category-desc ul,
.item-page ul,
.items-more {
  padding-left: 1.5em;
}

/* Other Mambo Stuff */

.article_seperator {
  display: block;
  height: 15px;
  font-size: 15px;
}

.pathway {
  color: #bbbbbb;
  font-size: 10px;
  text-decoration: none;
  font-weight: normal;

  a {
    color: #bbbbbb;
    text-decoration: none;
    font-weight: normal;
  }
}

.mosimage {
  border: 1px solid #cccccc;
  margin: 5px;
}

.mosimage_caption {
  margin-top: 2px;
  background: #efefef;
  padding: 1px 2px;
  color: #667;
  font-size: 10px;
  border-top: 1px solid #cccccc;
}

hr {
  width: 90%;
}

hr.separator {
  width: 40%;
}

a.pnav {}

.pnav:hover {}

.small {
  font-size: 10px;
  color: #ababab;
  line-height: 1.4;
}

.smalldark {
  font-size: 10px;
  color: #000;
  height: 10px;
  line-height: 1.4;
  text-align: left;
}

.sectiontableentry1 {
  text-align: left;
  background: #efefef;
  font-size: 10px;
  padding: 2px;
}

.sectiontableentry2 {
  background: #fff;
  font-size: 10px;
  text-align: left;
  padding: 2px;
}

.button {
  border: 1px solid #868686;
}

.inputbox,
textarea {
  border: 1px solid #868686;
}

.createdate {
  font-size: 10px;
  color: #ababab;
  height: 10px;
  line-height: 1.4;
}

.modifydate {
  font-size: 10px;
  color: #ababab;
  height: 20px;
  line-height: 1.4;
  margin-bottom: 5px;
}

a.readon:link,
a.readon:visited {
  font-size: 8pt;
  color: #0071bc;
  padding: 1px 3px 1px 12px;
  background: url(/templates/esperanto/images/blue_arrow.gif) no-repeat left;
  text-decoration: none;
}

.blog_more {
  font-size: 10px;
}

a.readon:hover,
a.redon:active {
  text-decoration: underline;
}

.blog,
.item-page {
  margin-bottom: 40px;
}

.contentheading,
.blog h2.subheading-category,
.sectiontableheader {
  color: #ffffff;
  background: #7b8287 url(/templates/esperanto/images/title_bg.gif) repeat-x top;
  font-size: 13pt;
  font-weight: normal;
  line-height: 1.4;
  margin: 0 0 10px 0;
  padding: 0px 10px 0px 10px;
  height: 30px;
  text-align: left;
}

.contentheading {

  a:link,
  a:visited {
    color: #ffffff;
    font-size: 9pt;
    text-align: right;
    text-decoration: none;
  }

  a:hover,
  a:active {
    text-decoration: underline;
  }
}

.contentpaneopen {
  font-size: 9pt;
  line-height: 1.4;
  width: 100%;
  border-collapse: collapse;

  td {
    padding-left: 10px;
    padding-right: 10px;
  }
}

table.contentpane td.contentheading {
  color: #0068b5;
  font-size: 11pt;
  padding: 0;
}

td.contentpane {
  padding-left: 10px;
  padding-right: 10px;
}

table.contentpane {
  ul {
    margin: 0px;
    padding: 0px;
    list-style: none;

    li {
      padding: 0px;
      margin: 0px;
      padding-left: 15px;
    }
  }
}

.contentpagetitle,
.blog .items-leading h2 {
  color: #0068b5;
  font-size: 12pt;
  padding: 0 0 5px 10px;
  margin: 1.7em 0 0 0;
}

.contentdescription {
  font-size: 10px;
}

.categorytitle {
  font-family: 'OpenSansSemiBold';
  font-size: 16px;
  height: 25px;
  line-height: 25px;
}

.componentheading {
  color: #ffffff;
  background: #7b8287 url(/templates/esperanto/images/title_bg.gif) repeat-x top;
  font-size: 13pt;
  line-height: 30px;
  padding: 0px 10px 0px 10px;
  margin: 0 0 20px 0;
  display: block;
  height: 30px;
}

a.category {
  text-decoration: underline;
}

a.category:hover {
  text-decoration: underline;
}

.readmore {
  padding-top: 0;
  margin-top: -0.4em;
}

.category-desc,
.category-desc table,
.item-page p,
.item-page h3,
.item-page h4,
.blog p,
.blog h2,
.blog h3,
.blog h4 {
  padding-left: 10px;
}

.blog {
  p {
    margin-bottom: 10px;
  }

  .category-desc {
    p {
      padding-left: 0;
    }
  }
}

.item-page p.pl40 {
  padding-left: 39px;
}

.infotable {
  margin-bottom: 25px;
}

.category-desc ul,
.item-page ul,
.items-more {
  padding-left: 1.5em;
}

.moduletable h3 {
  margin-top: 20px;
  color: #ffffff;
  font-size: 9pt;
  font-weight: normal;
  text-align: left;
  background: #0068b5 url(/templates/esperanto/images/module_header_r_bg.gif) no-repeat left;
  padding: 3px 10px 3px 18px;
}

.content_left .moduletable div {
  font-size: 9pt;
  color: #505d63;
  padding: 0 5px 0 18px;
}

.moduletable {
  h4 {
    margin: 1.5em 0 0em 0;
    padding: 0;
  }

  p {
    margin-top: 0.5em;
  }
}

.content_right {
  .vk {
    padding-bottom: 0;
  }

  .moduletable {
    width: 260px;
    font-size: 9pt;
    padding: 15px 0 15px 0;

    h3 {
      color: #ffffff;
      background: #7b8287 url(/templates/esperanto/images/title_bg.gif) repeat-x top;
      font-size: 9pt;
      line-height: 30px;
      padding: 0px 10px 0px 10px;
      margin: 0;
      height: 30px;
    }

    div,
    .fb {
      font-size: 9pt;
      color: #505d63;
      padding: 0 18px 0 10px;
    }
  }
}

.signature {
  color: #61696e;
  font-family: 'OpenSansItalic';
}

.poll {
  font-size: 10px;
  text-align: center;
}

.back_button a {
  font-size: 10px;
  font-weight: normal;
}

.blogsection {
  font-size: 10px;
}

.blog_more ul li {
  list-style: none;
  padding-left: 14px;
  line-height: 1.4;
}

table.contenttoc {
  border: 1px solid #cccccc;
  padding: 3px;

  th {
    font-family: 'OpenSansSemiBold';
    font-size: 10px;
    border-bottom: 1px solid #1e6fab;
  }
}

form {
  margin: 0px;
  padding: 0px;
}

/* Texts */

.infotable {
  th {
    color: #ffffff;
    font-size: 9pt;
    font-weight: normal;
    background: #0071bc;
  }

  td {
    background: #edeff2;
  }

  th,
  td {
    padding: 6px;
    vertical-align: middle;
    /*font-size: 9pt;*/
    font-size: 8pt;
    font-weight: normal;
    border: 1px solid white;
  }
}

.subtitle {
  color: #0071bc;
  font-size: 11pt;
}

.red {
  color: #c1272d;
}

.item-page em {
  /*font-size: 12px;*/
}

#top-menu {
  ul.nav.menu {
    margin: 0px;
    padding: 0px;
  }

  li {
    display: block;
    float: left;
    height: 51px;
    width: 141px;
    line-height: 51px;
    position: relative;
    cursor: pointer;

    ul {
      display: none;
      position: absolute;
      top: 51px;
      left: 0px;
      z-index: 1000000;
    }
  }

  li.item-104 ul {
    right: 10px;
  }
}

/*#top-menu li.parent ul,*/
#top-menu {

  li.parent:active ul,
  li.parent:focus ul,
  li.parent:hover ul {
    display: block;
    z-index: 1000000;
    width: 279px;
    padding: 0px;
    margin: 0px;
  }

  li.parent ul li {
    background: inherit;
    width: 253px;
    display: block;
    min-height: 51px;
    height: auto;
    padding: 0px 15px;
    margin-top: 1px;
  }

  li.parent.item-101 ul li {
    background: #ef4929;
  }

  li.parent.item-102 ul li {
    background: #d4c927;
  }

  li.parent.item-157 ul li {
    background: #3087c8;
  }

  li.parent.item-163 ul li {
    background: #ee3696;
  }

  li.parent {
    ul {
      li {
        a {
          height: auto;
          font-size: 17px;
          line-height: 1.4;
          width: auto;
          text-align: left;
          padding: 16px 0px;
        }
      }

      li:hover a {
        color: white;
      }
    }
  }

  li.parent.item-101 ul li:hover {
    /*background: red;*/
  }

  li.parent.item-102 ul li:hover {
    background: #b9af23;
  }

  li.parent.item-157 ul li:hover {
    background: #1e6fab;
  }

  li.parent.item-163 ul li:hover {
    /*background: red;*/
  }
}

.fp_bullets {
  td {
    background: #edf2f7;
    vertical-align: top;
    padding: 10px;
  }

  td.nopad {
    padding: 0px;
  }

  h3 {
    color: #c1272d;
    font-size: 1.1em;
    font-weight: normal;
    margin-top: 0;
  }

  a:link,
  a:visited {
    color: #0071bc;
    text-decoration: underline;
  }
}

p.info,
.innerpage .page-header+div p.info {
  background: url(/templates/esperanto/images/help.gif) no-repeat top left;
  padding-left: 62px;
  min-height: 35px;
}

p.info {
  a {
    color: #0071bc;
  }

  a:hover {
    color: orange;
  }
}

#ielts img {
  /*padding-left: 10px;*/
  width: 205px;
  height: 74px;
}

#toefl img {
  width: 215px;
  height: 56px;
}

#goethe img {
  width: 205px;
  height: 97px;
}

#goethe {
  margin-bottom: 35px;
  /*padding-left: 6px;*/
}

#ielts,
#toefl,
#goethe {
  text-align: center;
}

.linkthumb img {
  position: relative;
  /*left: -10px;*/
}

#cloak92173 a {
  border-bottom: dashed #0550a4 1px;
  text-decoration: none;
  color: #0550a4;
}

.enrolment-p {
  font-size: 15px;
}

#form-full-block {
  padding-left: 60px;
  padding-top: 35px;

  label {
    color: #6f757b;
  }

  .gcore-label-top {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  input {
    width: 350px;
    height: 32px;
    line-height: 32px;
    background: white;
    border: 1px solid black;
    margin-bottom: 14px;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 15px;
  }

  input.radio-from-block {
    width: auto;
    height: auto;
    line-height: 35px;
    background: white;
    border: 1px solid black;
    margin-bottom: 14px;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 15px;
    position: relative;
    top: 1px;

    &+label {
      color: #000;
    }
  }

  select {
    width: 350px;
    height: 32px;
    line-height: 32px;
    background: white;
    border: 1px solid black;
    margin-bottom: 14px;
    font-size: 15px;
  }

  #form-button11 {
    margin-top: 32px;
    background: #5ab0f5;
    color: #e8f5ff;
    width: 155px;
    height: 32px;
    line-height: 29px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #338acb;
    cursor: pointer;
  }
}

.href-list-for-enrolment {
  margin-top: 60px;
  margin-bottom: 50px;

  p {
    font-size: 15px;
  }

  a:link,
  a:visited {
    color: #0b63a9;
    text-decoration: underline;
    font-size: 15px;
  }

  a:hover {
    color: #e07f00;
  }

  table.category {
    margin: 1em;
    padding-left: 1em;
    margin-left: 46px;

    td {
      display: list-item;
      list-style-image: url(/templates/esperanto/img/li_15_15.png);

      a {
        position: relative;
        top: -3px;
      }
    }
  }
}

.gtooltip.gvalidation-error-tip {
  box-shadow: none;
  font-size: 12px;
  padding-right: 25px;
}

.gtooltip-close {
  top: 2px;
}

.flexslider .slides li .ico-img {
  display: inline-block;
  width: 27px;
  height: 27px;
  margin-right: 15px;
  float: left;
  margin-top: 17px;
  background: url('../img/slider-ico-1.png') no-repeat;
}

.welcome {
  margin-bottom: 25px;
}

.welcome-top {
  // background-image:  url(/templates/esperanto/img/girl_168_120.png);
  // width: 168px;
  // height: 120px;
  background-image: url(/templates/esperanto/img/enroll.svg);
  background-position: 0 0;
  background-repeat: no-repeat;

  background-size: 100%;
  width: 256px;
  height: 270px;
  display: block;
}

// Remove discount block
// .discount {
//     margin-bottom: 40px;
// }
// .discount-top {
//     //background-image:  url(/templates/esperanto/img/discount_257_169.png);
//     background-image:  url('/templates/esperanto/img/discount_257_168.png');
//     background-position: 0 0;
//     background-repeat: no-repeat;
//     width: 257px;
//     height: 168px;
//     background-size: 100%;
// }
// .welcome-bottom {
//     background-image:  url(/templates/esperanto/img/arr_260_50.png);
//     background-position: 100% 0;
//     background-repeat: no-repeat;
//     height: 48px;
//     width: 250px;
//     color: white;
//     padding: 3px 10px;
//     box-sizing: border-box;
//     display: block;
//     text-decoration: none;
//     background-size: 100%;
// }
.fp_bullets.bottom {
  ul {
    margin: 0 0 5px 20px;
  }

  .more {
    margin: 0 0 0 30px;
  }
}

.random-article {
  p {
    color: #4f555b;
    margin-bottom: 15px;
  }

  li {
    color: #4f555b;
    margin-bottom: 15px;
  }

  .signature {
    background: white;
    color: black;
    font-size: 13px;
    width: 230px;
    position: relative;
    left: -16px;
    padding-top: 14px !important;
    padding-right: 15px;
    text-align: left;
    padding-left: 15px;
    font-style: normal;
  }
}

#left-topper a {
  display: block;
  width: 380px;
  height: 90px;
  background-repeat: no-repeat;
  background-image: url(/templates/esperanto/img/logo-top_380_90.png);
  background-position: 0 0;
}

.services {
  h1 {
    color: black;
    font-size: 26px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin-top: 20px;
    padding: 0 0px;
    text-align: left;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
  }

  li {
    list-style: none;
    background: url(/templates/esperanto/img/li_15_15.png) no-repeat 0 4px;
    padding-left: 25px;
  }
}

/* retina backgrounds */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
only screen and (min--moz-device-pixel-ratio: 1.5),
only screen and (-o-min-device-pixel-ratio: 3/2),
/* 3/2 */
only screen and (min-device-pixel-ratio: 1.5),
/* 1.5 */
only screen and (min-resolution: 144dpi),
/* 144px */
only screen and (min-resolution: 1.5dppx) {
  #left-topper a {
    background-image: url(/templates/esperanto/img/logo-top_380_90@2x.png);
    background-position: 0 0;
    background-size: 100%;
  }

  .welcome-top {
    // background-image:  url(/templates/esperanto/img/girl_168_120@2x.png);
    background-image: url(/templates/esperanto/img/enroll.svg);
    background-position: 0 0;
  }

  .welcome-bottom {
    background-image: url(/templates/esperanto/img/arr_260_50@2x.png);
    background-position: 100% 0;
  }

  .discount-top {
    //background-image:  url(/templates/esperanto/img/discount_257_169@2x.png);
    background-image: url(/templates/esperanto/img/discount_257_168@2x.png);
    background-position: 0 0;
  }

  #fb_wall_ml h4 {
    background-image: url('../img/fb_homepage@2x.png');
    background-repeat: no-repeat;
    background-position: 0px 50%;
    background-size: 26px;
  }

  .flexslider .slides li.parent li {
    list-style-image: url(/templates/esperanto/img/li_15_15_white@2x.png) 0 0 no-repeat;
  }

  .content-category table.category td {
    list-style-image: url(/templates/esperanto/img/li_15_15@2x.png);
  }

  .href-list-for-enrolment table.category td {
    list-style-image: url(/templates/esperanto/img/li_15_15@2x.png);
  }

  #homepage-how-find-right .mail {
    background-image: url('../img/mail_dark@2x.png');
  }

  #homepage-how-find-right .phone {
    background-image: url('../img/phone_dark@2x.png');
  }

  #homepage-faq h5 {
    background-image: url('../img/page_h5@2x.png');
  }

  #mail {
    background-image: url('../img/mail_light@2x.png');
  }

  #phone {
    background-image: url('../img/phone_light@2x.png');
  }

  .item-page h1.page_h1,
  .module-block-name {
    background-image: url('../img/page_h1@2x.png');
  }

  .item-page h3.page_h3 {
    background-image: url('../img/page_h3@2x.png');
  }

  #google-map h4 {
    background-image: url('../img/page_h4@2x.png');
  }

  #homepage-gallery h4 {
    background-image: url('../img/page_h4_gallery@2x.png');
  }

  #slider-big-outer .Joombig-beautiful-banner+a.prev {
    background: url('../img/slider-left-arrow@2x.png') no-repeat scroll 0 0 transparent;
  }

  #slider-big-outer .Joombig-beautiful-banner+a.prev+a.next {
    background: url('../img/slider-right-arrow@2x.png') no-repeat scroll 0 0 transparent;
  }

  .ico-img {
    background: url('../img/slider-ico-1@2x.png') no-repeat;
  }

  #mail {
    background-position: 0px 3px;
  }

  #phone {
    background-position: 0px 2px;
  }

  body {
    // width: 1200px;
  }
}

//ipad horizontal
@media only screen and (max-width: 1040px) {
  body {
    width: 100%;
    overflow-x: hidden;
  }

  .content {
    width: 100%;
    min-width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }

  #left-aside {
    img {
      width: 100%;
      height: auto;
    }
  }

  #main {
    width: auto;

    img {
      width: 100%;
      height: auto;
    }

    #google-map {
      width: 100%;
      overflow: hidden;
    }

    #homepage-how-find-left {
      width: 100%;
    }

    #homepage-how-find-right {
      width: 100%;
    }
  }

  #right-aside {
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  }

  #right-aside {
    width: $respRightUnder1040;
    margin-left: -$respRightUnder1040;
  }

  #lefter {
    padding-right: $respMarginRightUnder1040;
  }

  #left-aside {
    width: $respLeftUnder1040;
  }

  #main {
    margin-left: $respMarginLeftUnder1040;
  }
}

//ipad vertical
@media only screen and (max-width: 991px) {
  #left-topper {
    width: 200px;
    padding-top: 0;

    a {
      width: 100%;
      -webkit-background-size: contain;
      background-size: contain;
    }
  }

  #homepage-faq {
    h5 {
      line-height: 1;
      margin-top: 20px;
      margin-bottom: 20px;
      background-position: 0px 10%;
    }
  }

  #mail {
    display: block;
    text-align: left;
  }

  #foo-menu {
    width: 100%;
    height: auto;

    li {
      display: block;
      width: 100%;
      height: auto;

      ul {
        position: static;
        padding-left: 15px;
      }
    }
  }

  #foo-menu .nav.menu>li {
    margin-bottom: 10px;
    width: 100%;
  }

  #foo-soc {
    float: left;
    margin-right: 12px;
    margin-top: 20px;
  }

  #footer p {
    margin-top: 20px;
  }

  #middle {
    width: 100%;
  }

  #right-aside {
    width: $respRightUnder991;
    margin-left: -$respRightUnder991;
  }

  #lefter {
    padding-right: $respMarginRightUnder991;
  }

  #left-aside {
    width: $respLeftUnder991;
    display: $respLeftDisplayUnder991;
  }

  #main {
    margin-left: $respMarginLeftUnder991;
  }
}

//phones

@media only screen and (max-width: 767px) {
  .item-page h3.page_h3 {
    line-height: 1;
    background-position: 40px 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  #google-map-address td {
    display: block;
    padding-bottom: 10px;
  }

  #right-aside {
    width: $respRightUnder767;
    margin-left: -$respRightUnder767;
  }

  #lefter {
    padding-right: $respMarginRightUnder767;
  }

  #left-aside {
    width: $respLeftUnder767;
  }

  #main {
    margin-left: $respMarginLeftUnder767;
  }
}

@media only screen and (max-width: 570px) {
  #topper {
    height: 220px;
  }

  #left-topper {
    width: 100%;

    a {
      background-position: 100% 50%;
    }
  }

  #top-menu {
    width: 100%;

    li {
      width: 25%;
    }

    a,
    .nav-header {
      width: 100%;
    }

    li.parent {
      ul {
        li {
          width: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 570px) {
  #right-aside {
    display: none;
  }

  #lefter {
    padding-right: 0;
  }

  #main {
    width: 100%;
    margin-left: 0;
  }

  #mail {
    display: inline-block;
  }

  #phocagallery-module-ri {
    width: 100% !important;
  }

  #fb_wall_ml {
    overflow: hidden;
  }

  #topper {
    height: 150px;
  }

  #left-topper {
    height: 30px;
    float: none;

    a {
      height: 30px;
      background-position: 50% 50%;
    }
  }

  #right-topper {
    float: none;
    text-align: center;
  }

  #middle {
    padding-top: 20px;
  }

  .category-list .content-category h2 {
    margin-bottom: 20px;
  }

  #top-menu li.parent ul li {
    width: 90%;
  }
}

@media only screen and (max-width: 479px) {
  #fb_wall_ml {
    display: none;
  }

  #homepage-gallery {
    overflow: hidden;
  }

  .pg-cv-box-mod-ri {
    width: 30% !important;
  }

  #top-menu {

    a,
    .nav-header {
      font-size: 14px;
    }
  }

  #homepage-faq h6 {
    font-size: 20px;
  }
}