// Vertical iPad (Max resolution) fixes
@media only screen and (max-width: 1023px) {
  #left-aside {
    float: left;
    display: block;
    width: 160px;
  }

  #main {
    margin-left: 180px;
  }

  #lefter {
    padding-right: 0;
  }

  #right-aside {
    display: none;
  }

  .welcome-top {
    width: 166px;
    height: 180px;
  }
}

// Vertical phones fixes
@media only screen and (max-width: 767px) {
  #new-horizont-slider {
    display: none;
  }

  #left-aside {
    width: 100%;
    float: none;

    img {
      // width: 70%;
      display: none;
    }
  }

  .welcome-top {
    width: 320px;
    height: 340px;
    margin: auto;
  }

  #main {
    margin-left: 0;
    float: none;
    width: 100%;
  }

  #google-map {
    margin-top: -40px;
  }

  #google-map-show {
    padding-left: 0;
    height: 430px;

    iframe {
      width: 100% !important;
      height: 430px !important;
    }
  }

  #phocagallery-module-ri {
    padding-left: 0;
  }
}

// Vertical iPad ONLY!!!
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  #foo-menu {
    .nav.menu > li {
      position: relative;
      margin-right: 20px;
      list-style: none;
      width: 30%;
      float: left;
    }
  }
}

//ipad horizontal
@media only screen and (max-width: 1040px) {
}

//ipad vertical
@media only screen and (max-width: 991px) {
}

//phones

@media only screen and (max-width: 767px) {
}
@media only screen and (max-width: 570px) {
}

@media only screen and (max-width: 570px) {
}
@media only screen and (max-width: 479px) {
}
