@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/OpenSansRegular/OpenSansRegular.eot');
  src: url('../fonts/OpenSansRegular/OpenSansRegular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansRegular/OpenSansRegular.woff') format('woff'),
    url('../fonts/OpenSansRegular/OpenSansRegular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../fonts/OpenSansLight/OpenSansLight.eot');
  src: url('../fonts/OpenSansLight/OpenSansLight.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansLight/OpenSansLight.woff') format('woff'),
    url('../fonts/OpenSansLight/OpenSansLight.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansItalic';
  src: url('../fonts/OpenSansItalic/OpenSansItalic.eot');
  src: url('../fonts/OpenSansItalic/OpenSansItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansItalic/OpenSansItalic.woff') format('woff'),
    url('../fonts/OpenSansItalic/OpenSansItalic.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../fonts/OpenSansBold/OpenSansBold.eot');
  src: url('../fonts/OpenSansBold/OpenSansBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansBold/OpenSansBold.woff') format('woff'),
    url('../fonts/OpenSansBold/OpenSansBold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansExtraBold';
  src: url('../fonts/OpenSansExtraBold/OpenSansExtraBold.eot');
  src: url('../fonts/OpenSansExtraBold/OpenSansExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansExtraBold/OpenSansExtraBold.woff') format('woff'),
    url('../fonts/OpenSansExtraBold/OpenSansExtraBold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansExtraBoldItalic';
  src: url('../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot');
  src: url('../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.woff')
      format('woff'),
    url('../fonts/OpenSansExtraBoldItalic/OpenSansExtraBoldItalic.ttf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansBoldItalic';
  src: url('../fonts/OpenSansBoldItalic/OpenSansBoldItalic.eot');
  src: url('../fonts/OpenSansBoldItalic/OpenSansBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansBoldItalic/OpenSansBoldItalic.woff') format('woff'),
    url('../fonts/OpenSansBoldItalic/OpenSansBoldItalic.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansRegularItalic';
  src: url('../fonts/OpenSansRegularItalic/OpenSansRegularItalic.eot');
  src: url('../fonts/OpenSansRegularItalic/OpenSansRegularItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansRegularItalic/OpenSansRegularItalic.woff')
      format('woff'),
    url('../fonts/OpenSansRegularItalic/OpenSansRegularItalic.ttf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('../fonts/OpenSansSemiBold/OpenSansSemiBold.eot');
  src: url('../fonts/OpenSansSemiBold/OpenSansSemiBold.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansSemiBold/OpenSansSemiBold.woff') format('woff'),
    url('../fonts/OpenSansSemiBold/OpenSansSemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'OpenSansSemiBoldItalic';
  src: url('../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot');
  src: url('../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.woff')
      format('woff'),
    url('../fonts/OpenSansSemiBoldItalic/OpenSansSemiBoldItalic.ttf')
      format('truetype');
  font-style: normal;
  font-weight: normal;
}
