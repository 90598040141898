body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscal;
  text-rendering: optimizeLegibility;
}

#left-aside-knowwhat {
  position: relative;
  z-index: 2;
}

#google-map-address {
  // padding-left: 0;
}

#google-map-show {
  iframe {
    max-width: 520px;
  }
}

#homepage-how-find-right .mail a {
  font-size: 17px;
}

// #homepage-faq {
//   h5 {
//     padding-left: 0;
//   }

//   h6 {
//     padding-left: 0;
//   }
// }